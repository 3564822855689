@mixin cent {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &::selection {
    color: var(--color0);
    background: var(--colorX);
  }
}

.App {
  width: 100vw;
  height: 100vh;
  background: var(--color0);
  --color0: #f1f1f1;
  --colorX: #222;
  --color_bak: #fff;
  --color_tab_hover: #222;
  --shadow0: 0px 1px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  .App_container {
    margin-top: 4em;
    width: 100%;
    height: calc(100% - 4em);
    // min-height: 110vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    // .before-loader {
    //   height: 120vh;
    //   display: none;
    // }

    .the_suspense {
      width: 100vw;
      height: 100vh;
    }

    .before-loader {
      width: 100%;
      height: 100%;
      background-color: var(--color0);
      position: fixed;
      z-index: 5;
      @include cent();
      display: flex;
    }

    .hover_loader_container {
      width: 100%;
      height: 100%;
      background-color: var(--color0);
      position: fixed;
      z-index: 2;
      @include cent();
      display: none;
    }

    .hover_loader {
      top: -4em;

      .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }

      .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--colorX);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }

      .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }

      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }

      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(24px, 0);
        }
      }
    }

    //!#####################################################################
    @import "./TheFooter";
  }
}

@import "./TopBare";
// ################################################
@import "./Accueil";
// ################################################
@import "./Travaux";
// ################################################
@import "./Services";
// ################################################
@import "./Contacts";
// ################################################
@import "./Gallery";
// ################################################
@import "./Immeuble_Talles";
// ################################################
@import "./Villa_Onomo";
// ################################################
@import "./Appartement_B_Home_1";
// ################################################
@import "./Design_Bureau";
// ################################################
@import "./Façade_Immeuble";
// ################################################
@import "./Appartement-F4-A";
// ################################################
@import "./Gym";
// ################################################
@import "./Cité_1";
// ################################################
@import "./Villa_A";
// ################################################
@import "./Villa_B";
// ################################################
@import "./Villa_Teranga_Al_Amin";
// ################################################
@import "./Villa_Samb";
// ################################################
@import "./Villa_Sarr";
// ################################################
@import "./Formation";

//?#########################################################
