@mixin cent {
  display: flex;
  justify-content: center;
  align-items: center;
}

#Accueil {
  width: 100%;
  height: auto;
  z-index: 1;

  .Accueil_Slider-2 {
    width: 100%;
    height: 70vw;
    @include cent();
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;

    .thumbs {
      display: none;
    }

    .control-dots {
      z-index: 10;
      position: absolute;
      bottom: 8%;
      left: 50%;
      transform: translate(-50%);
      @include cent();

      .dot {
        transform: scale(1.2);
        margin: 0.6em;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
    }

    .carousel-status {
      display: none;
    }

    .sliders {
      background-position: inherit;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      @include cent();
      flex-direction: column;
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      transition-duration: 1s, 0s;
      transition-property: opacity, background-position-y;

      .sliders_inner_container {
        position: absolute;
        background-position: inherit;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        @include cent();
        flex-direction: column;
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        transition-duration: 1s, 0s;
        transition-property: opacity, background-position-y;

        // opacity: .5;
        .slider_title {
          width: 55%;
          font-size: 5em;
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
            0 1px 2px rgba(0, 0, 0, 0.24);
          @include cent();
          align-items: flex-start;
          line-height: 1.2em;
          flex-direction: column;
          font-family: "Montserrat", sans-serif;
          font-weight: 900;
          top: -6%;
          color: white;

          p {
            text-align: left;
          }
        }

        .slider_date {
          font-family: "Montserrat", sans-serif;
          font-size: 1.5em;
          width: 55%;
          color: var(--color0);
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.02),
            0 1px 2px rgba(0, 0, 0, 0.24);
          @include cent();
          align-items: flex-start;
          flex-direction: column;
          font-weight: 300;
          top: -6%;
        }
      }

      img {
        // display: none;
        width: 100%;
        height: 100%;
        // display: none;
        // position: absolute;
      }
    }
  }

  //!#####################################################################
  .archviz_presantation {
    height: 25em;
    width: 100%;
    @include cent();
    flex-wrap: wrap;

    .qui_somme_nous {
      width: 80%;
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
      font-size: 1.8em;
    }

    p {
      width: 50%;
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      text-align: center;
      font-size: 1.2em;
    }

    .network_container {
      font-size: 0.8em;
      width: 80%;
      @include cent();
      justify-content: flex-end;
      flex-direction: row;
      color: var(--colorX);

      a {
        color: var(--colorX);

        &:hover {
          transform: scale(1.5);
          color: #000;
        }

        &:active {
          transform: scale(0.9);
        }

        .network {
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          text-align: center;
          font-size: 2em;
          cursor: pointer;
        }
      }
    }
  }

  //!#####################################################################
  .AccueilBigProjectsSection {
    width: 100%;
    min-height: 50em;

    .One_Project_In_Home {
      min-height: 48em;
      display: grid;
      grid-gap: 0.5em;

      .image_container {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transition-duration: 0.4s;
        background-color: rgba(0, 0, 0, 0.3);

        &:hover {
          background-size: 105%;
        }
      }

      .info_container {
        width: 100%;
        height: 100%;
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        @include cent();
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40% 60%;

        .title {
          margin: auto;
          @include cent();
          justify-content: flex-start;
          align-items: flex-end;
          width: 80%;
          height: 70%;
          font-family: "Montserrat", sans-serif;
          font-size: 2em;
          font-weight: 900;
          text-decoration: none;
        }

        .description {
          margin: auto;
          margin-top: 4em;
          width: 80%;
          @include cent();
          justify-content: flex-start;
          align-items: flex-start;
          font-family: "Montserrat", sans-serif;
          font-size: 1em;
          font-weight: 700;
          flex-wrap: wrap;

          .the_link {
            margin-top: 1em;
            font-size: 0.71em;
            text-decoration: none;
            padding: 0.3em;
            border-radius: 0.2em;

            &:hover {
              transform: scale(1.1);
            }

            ion-icon {
              bottom: -0.2em;
            }
          }

          .the_link:nth-child(2) {
            margin-right: 2em;
          }

          .bar {
            height: 0.15em;
            width: 8em;
            position: absolute;
            top: -1em;
            left: 0%;
          }
        }
      }
    }

    .right {
      grid-template-columns: 30em repeat(2, 1fr);
      grid-template-rows: 1fr;

      .image_container {
        grid-column: 2 / 4;
        grid-row: 1 / 1;
      }

      .info_container {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
      }
    }

    .left {
      grid-template-columns: repeat(2, 1fr) 30em;
      grid-template-rows: 1fr;

      .image_container {
        grid-column: 1 / 3;
        grid-row: 1 / 1;
      }

      .info_container {
        grid-column: 3 / 3;
        grid-row: 1 / 1;
      }
    }
  }

  //!#####################################################################
  .galerie_presantation {
    height: 15em;
    width: 100%;
    @include cent();
    flex-wrap: wrap;

    .nos_réalisations {
      width: auto;
      padding: 0.4em;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 0.8em;
      text-decoration: none;
      color: var(--colorX);
      border: 0.1em solid #000;
      border-radius: 0.3em;

      &:hover {
        transform: scale(1.1);
      }

      ion-icon {
        bottom: -0.2em;
      }
    }

    .galerie_container {
      width: 100%;
      // background-color: skyblue;
      padding-left: 10vw;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 2em;
    }
  }

  //!#####################################################################
  .the_galerie_presantation_container {
    width: 100%;
    height: auto;
    @include cent();
    flex-wrap: wrap;
    // background-color: aqua;
    background-color: var(--colorX);

    img {
      width: 60%;
      margin-top: 10em;
    }

    .the_title {
      width: 60%;
      font-family: "Montserrat", sans-serif;
      font-size: 1.5em;
      color: var(--colorX);
      font-weight: 700;
      margin: 1em 0em 0em 0em;
    }

    .the_date {
      width: 60%;
      font-family: "Montserrat", sans-serif;
      font-size: 1em;
      color: var(--colorX);
      font-weight: 100;
      margin: 1em 0em 5em 0em;
    }
  }

  //!#####################################################################
  .the_galerie_container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .one_galerie_element {
      width: calc(100% - 0.01em);
      @include cent();
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      overflow: hidden;
      border: 0.01em solid var(--colorX);

      img {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        transition-duration: 1s;
        transform: scale(1.1);
      }

      &:hover {
        img {
          transform: scale(1.2);
        }

        .one_galerie_element_hover {
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7);

          .iner_container {
            top: 0%;
          }
        }
      }

      .one_galerie_element_hover {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        width: 100%;
        height: 0%;
        overflow: hidden;
        @include cent();
        flex-wrap: wrap;
        background-color: rgba(255, 255, 255, 0);
        transition-duration: 0s, 1s;
        transition-property: height, background-color;

        .iner_container {
          width: 100%;
          height: auto;
          @include cent();
          flex-wrap: wrap;
          top: 6%;
          transition-duration: 0.3s;

          .Icon-to-click-on {
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            @include cent();
            font-size: 2.3em;
            color: var(--colorX);
            cursor: pointer;
            transform: scale(1);

            &:hover {
              transform: scale(1.2);
            }

            &:active {
              transform: scale(1);
            }
          }

          .titre {
            width: 95%;
            min-height: 1em;
            height: auto;
            @include cent();
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 1em;
            text-align: center;
            text-decoration: none;
            color: var(--colorX);

            &:hover {
              transform: scale(1.1);
            }
          }

          .date {
            width: 100%;
            height: 1em;
            @include cent();
            font-family: "Montserrat", sans-serif;
            font-size: 0.7em;
            font-weight: 100;
          }
        }
      }
    }
  }

  //!#####################################################################
}

//!############################################
@media all and (max-width: 1000px) {
  #Accueil {
    .Accueil_Slider-2 {
      // height: auto;
      overflow: hidden;
      height: 60vh;
      // background-color: #86c5e5;

      .control-dots {
        .dot {
          transform: scale(1.3);
        }
      }

      .sliders {
        background-size: 100%;
        background-size: cover;
        background-position-x: 30%;
        transition-duration: 0s, 0s;
        transition-property: opacity, background-position-y;
        // background-color: #cfab8b;
        height: 65vh;
        overflow: hidden;

        img {
          object-fit: cover;
        }

        .sliders_inner_container {
          height: 100%;
          overflow: hidden;

          .slider_title {
            width: 80%;
            font-size: 2em;
            margin-left: 0%;
            // opacity: .9;
          }

          .slider_date {
            width: 80%;
            font-size: 0.9em;
            margin-left: 0vw;
          }
        }
      }

      .sliders:nth-child(1) {
        // background-position-x: 60%;
        img {
          // object-fit: cover;
          // left: -23%;
          // background-position-x: 60%;
        }

        .sliders_inner_container {
          .slider_title:nth-child(1) {
            color: var(--color0);
            opacity: 0.9;
          }
        }
      }
    }

    //!#####################################################################
    .archviz_presantation {
      .qui_somme_nous {
        width: 100%;
        @include cent();
      }

      p {
        width: 80%;
      }

      .network_container {
        justify-content: center;
      }
    }

    //!#####################################################################
    .AccueilBigProjectsSection {
      width: 100%;
      min-height: auto;

      .One_Project_In_Home {
        min-height: 34em;
        height: auto;
        grid-gap: 0em;
        padding-bottom: 12%;

        .image_container {
          width: 100%;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          border-radius: 1em;
          background-color: rgba(0, 0, 0, 0.3);

          &:hover {
            background-size: 100%;
          }
        }

        .info_container {
          .title {
            font-size: 1.3em;
            width: 90%;
            transform: scale(1);

            .bar {
              bottom: -0.3em;
            }
          }

          .description {
            margin-top: 1em;
            font-size: 0.7em;
            width: 90%;

            .the_link {
              font-size: 1.2em;
            }
          }
        }
      }

      .right {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);

        .image_container {
          grid-column: 1 / 1;
          grid-row: 1 / 1;
        }

        .info_container {
          grid-column: 1 / 1;
          grid-row: 2 / 2;
        }
      }

      .left {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);

        .image_container {
          grid-column: 1 / 1;
          grid-row: 1 / 1;
        }

        .info_container {
          grid-column: 1 / 1;
          grid-row: 2 / 2;
        }
      }
    }

    //!#####################################################################
    .galerie_presantation {
      height: 10em;

      .nos_réalisations {
        padding-left: 1em;
        font-size: 0.8em;
      }

      .galerie_container {
        font-size: 1.3em;
      }
    }

    //!#####################################################################
    .the_galerie_container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(13, 1fr);
      height: 95em;

      .one_galerie_element {
        @include cent();

        img {
          transform: scale(1);

          &:hover {
            transform: scale(1);
          }
        }

        &:hover {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }
}
