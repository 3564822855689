@mixin cent {
  display: flex;
  justify-content: center;
  align-items: center;
}

#Gallery {
  width: 100%;
  min-height: 100vh;
  z-index: 1;

  .the_galerie_presantation_container {
    width: 100%;
    height: auto;
    @include cent();
    flex-wrap: wrap;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 60%;
      margin-top: 2em;

      &:hover {
        transform: scale(1.01);
      }

      &:active {
        transform: scale(1);
      }
    }

    .the_title {
      width: 60%;
      font-family: "Montserrat", sans-serif;
      font-size: 2em;
      font-weight: 700;
      margin: 1em 0em 0em 0em;
      color: var(--colorX);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        transform: scale(1.01);
      }
    }

    .the_date {
      width: 60%;
      font-family: "Montserrat", sans-serif;
      font-size: 1em;
      color: var(--colorX);
      font-weight: 100;
      margin: 1em 0em 5em 0em;
    }
  }

  .the_galerie_container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .one_galerie_element {
      width: calc(100% - 0.01em);
      @include cent();
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      overflow: hidden;
      border: 0.01em solid var(--colorX);

      img {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        transition-duration: 1s;
        transform: scale(1.1);
      }

      &:hover {
        img {
          transform: scale(1.2);
        }

        .one_galerie_element_hover {
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7);

          .iner_container {
            top: 0%;
          }
        }
      }

      .one_galerie_element_hover {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        width: 100%;
        height: 0%;
        overflow: hidden;
        @include cent();
        flex-wrap: wrap;
        background-color: rgba(255, 255, 255, 0);
        transition-duration: 0s, 1s;
        transition-property: height, background-color;

        .iner_container {
          width: 100%;
          height: auto;
          @include cent();
          flex-wrap: wrap;
          top: 6%;
          transition-duration: 0.3s;

          .Icon-to-click-on {
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            color: rgba(0, 0, 0, 0.51);
            @include cent();
            font-size: 2.3em;
            transform: scale(1);

            &:hover {
              transform: scale(1.2);
            }

            &:active {
              transform: scale(1);
            }
          }

          .titre {
            width: 95%;
            min-height: 1em;
            height: auto;
            @include cent();
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 1em;
            text-align: center;
            text-decoration: none;
            color: var(--colorX);

            &:hover {
              transform: scale(1.1);
            }
          }

          .date {
            width: 100%;
            height: 1em;
            @include cent();
            font-family: "Montserrat", sans-serif;
            font-size: 0.7em;
            font-weight: 100;
          }
        }
      }
    }
  }
}

.display_image_container {
  position: fixed;
  top: 4em;
  width: 100vw;
  min-height: calc(100vh - 4em);
  background-color: rgba(#f1f1f1, 0.9);
  @include cent();
  z-index: 110;

  div {
    width: 50%;
    background-color: var(--colorX);
    border: 0.5em solid var(--colorX);
    border-bottom: 1em solid var(--colorX);
    border-radius: 0.2em;

    .close_image_container {
      position: absolute;
      width: 3em;
      height: 3em;
      border-radius: 5%;
      background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.5);
      transition-duration: 0.2s;
      z-index: 5;
      right: 0%;
      top: 0%;
      margin: 0.5em;
      border: 0em solid var(--colorX);
      transform: scale(1);

      &::before {
        content: "";
        position: absolute;
        transform: rotate(-45deg);
        top: calc(50% - 0.1em);
        left: 25%;
        width: 50%;
        height: 0.15em;
        background-color: var(--color0);
        transition-duration: 0.2s;
      }

      &::after {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        top: calc(50% - 0.1em);
        left: 25%;
        width: 50%;
        height: 0.15em;
        background-color: var(--color0);
        transition-duration: 0.2s;
      }

      &:hover {
        background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.5);
        transform: scale(1.2);
      }

      &:active {
        background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.5);
        transform: scale(1);
      }
    }
  }
}

//!############################################
@media all and (max-width: 1000px) {
  #Gallery {
    width: 100%;
    z-index: 1;

    .the_galerie_presantation_container {
      img {
        width: 100%;
      }

      .the_title {
        width: 90%;
        font-size: 1.5em;

        &:hover {
          transform: scale(1);
        }
      }

      .the_date {
        width: 90%;
        font-size: 0.8em;
      }
    }

    //!############################################
    .the_galerie_container {
      grid-template-rows: repeat(13, 15em);
      grid-template-columns: repeat(1, 1fr);
      height: auto;

      .one_galerie_element {
        @include cent();

        img {
          transform: scale(1);

          &:hover {
            transform: scale(1);
          }
        }

        &:hover {
          img {
            transform: scale(1);
          }
        }

        .one_galerie_element_hover {
          background-color: aqua;
          opacity: 1;
        }
      }
    }

    //!############################################
    .display_image_container {
      top: 4em;
      min-height: calc(100vh - 4em);
      background-color: rgba(255, 255, 255, 0.9);

      div {
        width: 97%;
        border: 0.1em solid var(--colorX);
        border-bottom: 0.6em solid var(--colorX);

        .close_image_container {
          width: 3em;
          height: 3em;
          top: -3.5em;
          margin: -0.1em;
        }
      }
    }
  }
}
