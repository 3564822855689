@mixin cent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Formation {
  display: grid;
  grid-template-rows: auto auto auto auto auto auto auto;
  grid-template-columns: 1fr;
  // background-color: salmon;
  min-height: 315vw;

  .formation_section_1 {
    grid-column: 1;
    grid-row: 1;
    font-family: "Ubuntu", sans-serif;
    font-style: italic;

    .project_title {
      font-weight: 900;
      position: absolute;
      font-size: 7.5vw;
      left: 5%;
      top: 1.3%;
    }

    .project_title-2 {
      font-weight: 700;
      position: absolute;
      font-size: 3.8vw;
      left: 5%;
      top: 13%;
    }

    .link {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      color: #f1f1f1;
      position: absolute;
      font-weight: 500;
      font-size: 2vw;
      left: 64%;
      top: 32.2%;
      background-color: #1f1f1f;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-bottom: 0.4vw;
      text-decoration: none;
      white-space: nowrap;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 0.2em;
      &:hover {
        transform: scale(1.1);
      }

      em {
        font-size: 1.5vw;
      }
    }

    .mail {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      color: #f1f1f1;
      position: absolute;
      font-weight: 900;
      font-size: 2vw;
      left: 67%;
      top: 49%;
      line-height: 145%;
      background-color: #1f1f1f;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 0.2em;
      padding-left: 1vw;
      padding-right: 1vw;
      @include cent();
      .icon {
      }
    }

    .phone,
    .phone-2 {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      color: #f1f1f1;
      position: absolute;
      font-weight: 900;
      font-size: 2vw;
      left: 62%;
      top: 59.5%;
      line-height: 145%;
      background-color: #1f1f1f;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 0.2em;
      padding-left: 1vw;
      padding-right: 1.4vw;
      padding-top: 0.3vw;
      @include cent();
    }

    .phone-2 {
      top: 70%;
      left: 74%;
    }
  }

  .formation_section_2 {
    grid-column: 1;
    grid-row: 2 / 4;
    top: -1.5vh;

    .project_title {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      font-weight: 900;
      line-height: 90%;
      position: absolute;
      font-size: 4.5vw;
      left: 15%;
      top: 13%;
    }
    p {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      position: absolute;
      font-size: 1.5vw;
      left: 15%;
      top: 40%;
      font-weight: 550;
      line-height: 125%;
    }
  }
  .formation_section_3 {
    grid-column: 1;
    grid-row: 3 / 5;

    .picture {
      width: 8.5vw;
      height: 8.5vw;
      background-color: #1f1f1f;
      position: absolute;
      left: 35%;
      top: 34%;
      border-radius: 50%;
      // z-index: 2;
      background-image: url("./Images/SVG/My-pictur.jpg");
      background-size: 100%;
      &:hover {
        transform: scale(1.1);
      }
    }

    .project_title {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      font-weight: 900;
      line-height: 90%;
      position: absolute;
      font-size: 5.5vw;
      left: 5.5vw;
      top: 33%;
    }
    p {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      position: absolute;
      font-size: 1.5vw;
      left: 6%;
      top: 45%;
      font-weight: 550;
      line-height: 125%;
      em {
        font-size: 1.8vw;
        font-style: normal;
        font-weight: 900;
        margin-bottom: 3em;
      }
    }
    .link {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      color: #f1f1f1;
      position: absolute;
      font-weight: 500;
      font-size: 2vw;
      left: 6%;
      top: 67%;
      background-color: #1f1f1f;
      padding-left: 1vw;
      padding-right: 1vw;
      padding-bottom: 0.9vw;
      padding-top: 0vw;
      text-decoration: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 0.2em;
      &:hover {
        transform: scale(1.1);
      }
      em {
        font-style: normal;
        font-size: 1.5vw;
      }
    }
  }
  .formation_section_4 {
    grid-column: 1;
    grid-row: 5 / 6;
    height: 34vw;
    background-color: #ecd233;
    // z-index: 2;

    .project_title {
      font-weight: 900;
      position: absolute;
      font-size: 3.2vw;
      left: 4%;
      top: 15%;
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
    }

    .project_title-2 {
      font-weight: 500;
      position: absolute;
      font-size: 2.3vw;
      left: 4%;
      top: 41%;
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
    }

    .prix {
      font-family: "Ubuntu", sans-serif;
      font-style: italic;
      color: #1f1f1f;
      width: 45vw;
      position: absolute;
      font-weight: 900;
      left: 53.5%;
      top: 18%;
      em {
        font-weight: 700;
        font-size: 3vw;
        padding: 1.5vw;
      }
      strong {
        border: solid 0.01px#ff00002d;
        font-weight: 900;
        padding: 0.51vw;
        border-radius: 0.2em;
        padding-right: 1.3vw;
        font-size: 3.9vw;
      }
    }
    .men-prix {
      top: 40%;
      em {
        padding-right: 1.3vw;
      }
    }
    .link {
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      color: #f1f1f1;
      position: absolute;
      font-weight: 500;
      font-size: 2vw;
      left: 50%;
      transform: translateX(-50%);
      top: 77%;
      background-color: #1f1f1f;
      padding: 1vw 4vw;
      text-decoration: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 0.2em;
      &:hover {
        transform: translateX(-50%) scale(1.1);
      }
    }
    .theArrow {
      position: absolute;
      width: 5vw;
      left: 34%;
      transform: translateX(-50%);
      top: 80%;
      animation: animatename 1s linear infinite;
      transform: scale(1);
    }
    @keyframes animatename {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.09);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .formation_section_5 {
    grid-column: 1;
    grid-row: 6;
    height: 30vw;
    background-color: #96bddc;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 5vw;
    padding-left: 1.7vw;
    .theCertif {
      @include cent();
      margin: auto;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

//!########################################

@media all and (max-width: 1000px) {
  .Formation {
    padding-right: 0vw;
    .formation_section_1 {
      .project_title {
      }
      .project_title-2 {
      }
      .phone {
      }
      .link {
        font-size: 2.3vw;
        left: 62%;
        top: 45.2%;
        &:hover {
        }
        em {
        }
      }
      .mail {
        top: 61%;
      }
      .phone,
      .phone-2 {
        top: 73.5%;
      }
      .phone-2 {
        top: 86%;
      }
    }
    .formation_section_2 {
      .project_title {
        top: 5vw;
      }
      p {
        top: 24vw;
        font-size: 2.4vw;
      }
    }
    .formation_section_3 {
      .project_title {
        left: 2%;
      }
      p {
        font-size: 2.4vw;
        left: 2%;
        transform: translateY(6%);
        // top: 2vw;
        em {
          top: -1vw;
          font-size: 2.6vw;
        }
      }
      .link {
        left: 2%;
        top: 80%;
        font-size: 3vw;
        padding: 0vw 1.2vw;
        font-size: 4vw;
        padding-bottom: 1vw;
        em {
          font-size: 2vw;
        }
      }
    }
    .formation_section_4 {
      .link {
        font-size: 4vw;
        left: 50%;
        top: 70%;
        color: #f1f1f1;
      }
      .theArrow {
        width: 6vw;
        left: 28%;
        top: 75%;
      }
    }

    .formation_section_5 {
      height: 90vw;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      padding: 5vw;

      .theCertif {
      }
    }
  }
}
