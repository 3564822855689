@mixin cent {
    display: flex;
    justify-content: center;
    align-items: center;
}



.Touba_1_container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, auto);
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5em;

    .project_display {
        @include cent();
        flex-wrap: wrap;
        display: grid;
        grid-template-rows: repeat(10, auto);
        grid-template-columns: 1fr;
        padding-bottom: 2em;


        .project_title {
            font-family: 'Montserrat', sans-serif;
            font-size: 2em;
            font-weight: 800;
            margin-bottom: .2em;
        }

        .project_description {
            margin-bottom: 2em;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2em;
            font-weight: 400;
        }

        .TheImageContainer {
            width: 60vw;
            height: auto;
            margin-bottom: 3em;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            img {
                transition-duration: .3s;
                transform: scale(1);
                border-radius: 1em;

                &:hover {
                    transform: scale(1.03);
                }

            }
        }

        .the_video {
            width: 60vw;
            height: 45vw;
            margin-bottom: 3em;

            iframe {
                border: none;
                border-radius: 1.5em;
            }

            margin-bottom: 3em;
        }

        p {
            width: 60vw;
            margin-bottom: 2em;
            font-family: 'Montserrat', sans-serif;
            font-size: .8em;
            font-weight: 700;
        }
    }

    .tools {
        margin-top: 7em;
        margin-left: 2em;
        height: 15em;
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(2, auto);

        .theToolsTitle {
            height: 2em;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.8em;
            font-weight: 700;
            margin-bottom: .2em;
            grid-column: 1/3;
            grid-row: 1/1;
        }


        .theToolsIcon1 {
            width: 5em;
            grid-column: 1/1;
            grid-row: 2/2;
            @include cent();
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .theToolsIcon2 {
            width: 5em;
            grid-column: 2/2;
            grid-row: 2/2;
            @include cent();
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }

        .theToolsIcon3 {
            width: 5em;
            grid-column: 3/3;
            grid-row: 2/2;
            @include cent();
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}


//!########################################

@media all and (max-width: 1000px) {


    .Touba_1_container {
        padding-left: 5vw;
        padding-right: 0vw;

        .project_display {

            .project_title {
                font-size: 1.5em;
            }

            .the_video {
                width: 90vw;
                height: 57vw;
                display: none;

            }

            h2 {
                display: none;
            }

            .project_description {
                font-size: .7em;
            }

            .TheImageContainer {
                width: 90vw;
                margin-bottom: 1em;
            }

            p {
                width: 80vw;
            }
        }
    }
}